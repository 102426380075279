import axios from "axios";

const apires = axios.create({
  baseURL: 'https://pago.fydbymad.com/api',
  timeout: 20000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

export default apires;
