import React from 'react';

import logo from './logo.png';
import './App.css';

import { Alert, Box, Container } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, StaticTimePicker } from '@mui/x-date-pickers';

import * as Sentry from "@sentry/react";

import apires from './apires/apires';
import { logError } from './utils';
import dayjs from 'dayjs';


Sentry.init({
  dsn: "https://047d7947b9124cd99077329161b4f8ac@o4504911758360576.ingest.sentry.io/4504914577260544",
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay()
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

function App() {

  const [token, setToken] = React.useState(null);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [ordenInfo, setOrdenInfo] = React.useState(null);
  const [scheduleUnix, setScheduleUnix] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState(null);

  const onAcceptDate = (date) => {
    if (date) {
      const dateInHHMM = date.format('HH:mm');
      setSelectedDate(dateInHHMM);
      apires.post("/orden/timepicker", { time: dateInHHMM, token }).then(() => {
        setSuccess(true);
        setTimeout(() => {
          goToFyD();
        }, 5000);
      }).catch((error) => {
        logError(error);
      });
    } else {
      setError('Debes elegir una hora');
    }
  };

  const goToFyD = () => {
    window.location.href = "https://fydbymad.com";
  };

  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('o');
    setToken(token);
    apires.get(`/orden_info/${token}?is_token=1`).then((response) => {
      const ordenInfo = response.data;
      if (!ordenInfo?.id) {
        goToFyD();
      } else {
        setOrdenInfo(ordenInfo);
      }
    }).catch((error) => {
      logError(error);
      goToFyD();
    });
  }, []);

  React.useEffect(() => {
    if (ordenInfo?.scheduleunix) {
      setScheduleUnix(dayjs.unix(ordenInfo.scheduleunix));
    }
  }, [ordenInfo]);


  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="logo" className="App-logo"/>
      </header>
      <Container maxWidth="sm">
        <Box mt={2} display={error ? 'flex': 'none'}>
          <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }} variant="filled">
            {error}
          </Alert>
        </Box>
        <Box mt={2} display={!success ? 'flex': 'none'}>
          <Alert closeText='' severity="info" sx={{ width: '90%', justifyContent: 'center' }}>
            Elige la hora usando el reloj y presiona Aceptar, para continuar en el chatbot
          </Alert>
        </Box>
        <Box sx={{ my: 4 }}>
          <h2>Elige la hora para tu orden #{ordenInfo?.short_id}</h2>
          <h4 style={{color: 'rgb(152, 89, 156)'}}>A nombre de {ordenInfo?.client?.name}</h4>
          <p>
          {ordenInfo?.schedule?.datetime_raw?.split(",")[0] || ''} {selectedDate || ''}
          </p>
          <Box mt={2} display={success ? 'flex': 'none'}>
          <Alert onClose={() => goToFyD()} severity="success" sx={{ width: '100%' }} variant="filled">
            Puedes continuar en el chatbot, te redireccionaremos a https://fydbymad.com
          </Alert>
        </Box>
          {selectedDate ? <img src='/scheduled.gif' style={{ width: 150, height: 150 }} alt="loading" /> : <LocalizationProvider dateAdapter={AdapterDayjs}
            localeText={{
              timePickerAmpmLabel: 'AM/PM',
              timePickerHourLabel: 'Hora',
              timePickerMinuteLabel: 'Minutos',
              timePickerSecondLabel: 'Segundos',
              okButtonLabel: 'Aceptar',
              cancelButtonLabel: '',
              todayButtonLabel: 'Hoy',
              timePickerToolbarTitle: 'Elige una hora futura',
            }}
          >
            <StaticTimePicker
              ampm={false}
              minutesStep={15}
              onAccept={onAcceptDate}
              value={scheduleUnix}
              disablePast={ordenInfo?.schedule?.isToday}
            />
          </LocalizationProvider>}
        </Box>
      </Container>
    </div>
  );
}

export default App;
